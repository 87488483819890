import React from 'react';
import styled from 'styled-components';

import Page from '~/layout/LayoutMarketing';
import Image from '~/components/Image';
import LargeCTA from '~/components/LargeCTA';
import Opener from '~/components/works-with/Opener';
import UseCaseSection, {
  UseCaseScreenshot,
} from '~/components/use-cases/UseCaseSection';
import BenefitsOfHerald from '~/components/works-with/BenefitsOfHerald';
import HeraldSolution from '~/components/works-with/HeraldSolution';

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .container__image {
    flex: 1 1 auto;
  }
  .container__text {
    flex: 0 0 50%;
  }
  .text-medium {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .screenshots {
    position: relative;
  }
  .screenshots__wide {
    position: absolute;
    width: 75%;
    left: 0;
    top: 0;
  }
  .screenshots__narrow {
    position: absolute;
    width: 30%;
    right: 0;
    top: 2rem;
  }
  @media screen and (max-width: 600px) {
    .screenshots__wide {
      display: none;
    }
    .screenshots__narrow {
      position: absolute;
      width: 80%;
      right: 10%;
      top: 0;
    }
  }
`;

const WorksWithFront: React.FC = () => {
  return (
    <Page title="The Best Sidekick to Front">
      <Styled>
        <Opener app="front" />
        <HeraldSolution />
        <UseCaseSection
          orientation="center"
          title={
            <span className="text-medium">
              Herald's Front Plugin allows your team to tag customer
              conversations right from your Front inbox with a few clicks.
            </span>
          }
          contentStyle={{ height: '16rem', maxWidth: 'none' }}
          textStyle={{ maxWidth: '32rem', lineHeight: '1.6rem' }}
          content={
            <div className="screenshots">
              <UseCaseScreenshot className="screenshots__wide">
                <Image src="screenshots/front-plugin-2.png" />
              </UseCaseScreenshot>
              <UseCaseScreenshot className="screenshots__narrow">
                <Image src="screenshots/front-plugin-3.png" />
              </UseCaseScreenshot>
            </div>
          }
        />
        <BenefitsOfHerald />
        <LargeCTA title="Ready to supercharge your team's Front workflow?" />
      </Styled>
    </Page>
  );
};

export default WorksWithFront;
